import { useEffect, useRef } from 'react'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { getVasEntryPoints } from 'data/api'
import { transformVasEntryPointsResponse } from 'data/api/transformers/response'

import { VasEntryPoint } from 'constants/vas-entry-point'
import { AbTestVariant } from 'constants/abtest'

import useFetch from './useFetch'
import useAbTest from './useAbTest'

const useFetchVasEntryPoints = (entryPointNames: Array<VasEntryPoint>, isDisabled?: boolean) => {
  const {
    fetch: fetchVasEntryPoints,
    transformedData: data,
    isLoading,
    error,
  } = useFetch(getVasEntryPoints, transformVasEntryPointsResponse)
  const isVasHarmEnabled = useAbTest({ abTestName: 'vas_harm_v2' })?.variant === AbTestVariant.On
  const isClosetPromotionKillswitchEnabled = useFeatureSwitch('killswitch_closet_promotion_web')

  const filteredEntryPointNamesRef = useRef<Array<VasEntryPoint>>(
    isVasHarmEnabled || isClosetPromotionKillswitchEnabled
      ? entryPointNames.filter(entryPoint => entryPoint !== VasEntryPoint.PromotedClosets)
      : entryPointNames,
  )

  useEffect(() => {
    if (!filteredEntryPointNamesRef.current.length) return
    if (isDisabled) return

    fetchVasEntryPoints({ bannerNames: filteredEntryPointNamesRef.current })
  }, [fetchVasEntryPoints, isDisabled, filteredEntryPointNamesRef])

  return { data, isLoading, error }
}

export default useFetchVasEntryPoints
