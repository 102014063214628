'use client'

import { ReactNode } from 'react'
import classnames from 'classnames'
import { useInView } from 'react-intersection-observer'

export type Props = {
  width?: 'one-fifth' | 'full-row' | null
  item?: ReactNode
  onVisible: () => void
  children?: ReactNode
}

const GridItem = ({ children, item, width, onVisible }: Props) => {
  const content = item || children
  const cssClasses = classnames('feed-grid__item', {
    [`feed-grid__item--${String(width)}`]: width,
  })
  const { ref } = useInView({
    onChange: inView => {
      if (inView) onVisible()
    },
  })

  return (
    <div data-testid="grid-item" className={cssClasses} ref={ref}>
      <div className="feed-grid__item-content">
        <div className="u-flex-grow u-fill-width">{content}</div>
      </div>
    </div>
  )
}

export default GridItem
